@media (min-width: 600px) {
  .card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-right: 15px;
    flex: 1 0 0;
    min-width: 260px;
    max-width: 280px;
    min-height: 425px;
    cursor: pointer;
    text-decoration: none;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      min-height: 150px;

      img {
        max-width: 90%;
        max-height: 80%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-top: 10px;
      margin-bottom: 10px;

      .title {
        font-size: 22px;
        margin-bottom: 5px;
      }

      .text {
        font-size: 17px;
      }
    }

    .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 10px;
    }
  }
}

a {
  text-decoration: none;
}

// Mobile
@media (max-width: 600px) {
  .card {
    display: flex;
    min-width: 175px;
    max-width: 175px;
    min-height: 250px;
    max-height: 250px;
    font-size: 12px;
    border-radius: 20px;
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    padding: 10px;
    flex-direction: column;

    .header {
      img {
        max-height: 50px;
        max-width: 80%;
        display: flex;
        margin: auto;
        margin-top: 20px;
      }
      margin-bottom: 10px;
    }

    .text {
      margin: 5px 0px;
    }

    .title {
      font-size: 14px;
      color: white;
    }

    .button {
      display: flex;
      margin: auto;
      margin-bottom: 5px;
    }
  }
}

@media screen and (orientation: landscape) and (max-device-width: 900px) {
  .card {
    min-height: 200px;
    max-height: 200px;
    min-width: 120px;
    max-width: 120px;

    .header {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      margin-top: -25px;
      min-height: 120px;
      max-width: 60%;

      img {
        margin-top: 20px;
        max-height: 50px !important;
        max-width: 50px !important;
      }
    }

    .content {
      .title {
        margin-top: -50px;
        font-size: 10px;
      }

      .text {
        font-size: 8px;
      }
    }

    .button {
      button {
        height: 20px;
        min-width: 75px;
        font-size: 10px;
      }
    }
  }
}
