// Desktop

.footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  margin-top: auto;

  &.show {
    opacity: 1;
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  .lead {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: -2px;

    img {
      margin-right: 30px;
      margin-bottom: -15px;
    }
  }

  .copyright {
    display: flex;
    margin-top: auto;
    color: white;
    margin-left: 20px;
    font-size: clamp(0.6rem, 0.8vw, 1rem);
  }

  .logos {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    align-content: flex-end;
    margin-right: 20px;

    .badge {
      background-color: white;
      border-radius: 15px;
      margin-right: 25px;
      margin-bottom: -5px;
      padding: 10px 25px 10px 30px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: all 0.1s ease-in-out;
    }
    .asset {
      display: flex;
      margin-bottom: 0px;
    }

    img {
      margin-top: auto;

      &:not(.badge img) {
        margin-bottom: -5px;
      }
    }
  }

  img {
    max-width: 90%;
  }
}

@media (max-width: 600px) {
  .footer {
    display: flex;
    margin-top: 10px;
    .lead {
      img {
        margin-right: 40px !important;
        height: 18px;
        margin-bottom: -10px;
      }
    }

    .content {
      flex-direction: column-reverse;
    }

    .lead {
      margin-bottom: -15px;
    }

    .copyright {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .logos {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 600px) and (orientation: portrait) {
  .footer {
    .badge {
      padding: 5px 12px;
      margin: 0px 10px 0px 10px;
    }
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  .footer {
    margin-top: -30px !important;

    .badge {
      padding: 5px 12px;
      margin: 0px 10px 0px 10px;
    }
  }
}
