@media (min-width: 600px) {
  .button {
    height: 38px;
    min-width: 180px;
    border-radius: 20px;
    box-shadow: none;
    border: 0;
    background: white;
    -webkit-appearance: none;
    font-size: 16px;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}

@media (max-width: 600px) {
  .button {
    height: 20px;
    width: 80px;
    border-radius: 20px;
    box-shadow: none;
    border: 0;
    font-size: 12px;
  }
}
