// Desktop
@media (min-width: 600px) {
  .dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.show {
      opacity: 1;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    margin-top: -50px;
  }

  .cards {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    flex-wrap: wrap;

    .left,
    .right {
      display: flex;
      flex: 3 0 0;
    }

    .list {
      display: flex;
      flex: 17 0 0;
      flex-direction: row;
      justify-content: left;
    }
  }
}

// Mobile
@media (max-width: 600px) {
  .dashboard {
    .content {
      margin-top: 10px;

      .cards {
        margin-top: 10px;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
  }
}
