.welcome {
  display: flex;
  color: #fff;
  flex-direction: row;
  width: 100vw;
  min-height: 52px;

  .left,
  .right {
    display: flex;
    flex: 3 0 0;
  }

  .content {
    display: flex;
    flex: 17 0 0;
    flex-direction: column;
    .lead {
      min-height: 31px;
      font-size: clamp(1rem, 2.5vw, 2rem);
    }

    .pleaseSelect {
      font-size: 18px;
      min-height: 21px;
      margin-bottom: 20px;;
    }
  }
}

@media screen and (orientation: landscape) and (max-device-width: 900px) {
  .lead {
    min-height: 0px !important;
    h2 {
      font-size: 15px !important;
    }
  }

  .pleaseSelect {
    font-size: 12px !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 600px) {
  .welcome{
    .content{
      text-align: center;
    }
  }
}