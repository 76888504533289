$spinner-dark: #89a989;
$spinner-dark-background: rgba(137, 169, 137, 0.3);
$spinner: #ffffff;
$spinner-background: rgba(255, 255, 255, 0.3);
$spinner-xs: 15px;
$spinner-xs-lead: 14px;
$spinner-xs-sub: 12px;
$spinner-s: 38px;
$spinner-s-lead: 16px;
$spinner-s-sub: 14px;
$spinner-m: 47px;
$spinner-m-lead: 18px;
$spinner-m-sub: 16px;
$spinner-l: 60px;
$spinner-l-lead: 20px;
$spinner-l-sub: 18px;

$modal-title: #72bbdc;
$modal-header: 20px;
