@mixin blocking-overlay-before-show {
  background: rgba(255, 255, 255, 0.1) url("../../assets/overlay.png") repeat fixed
    center;
  position: fixed;
  z-index: 1018;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease;
  -webkit-transition: visibility 0.5s opacity 0.5s ease;
  display: block;
}

@mixin blocking-overlay-show {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s ease;
  -webkit-transition: visibility 0.5s opacity 0.5s ease;
}
