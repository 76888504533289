.user {
  &:hover {
    cursor: pointer;
  }
  img {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.crispLogo {
  margin: 20px 30px 0px 0px;
}

// Desktop
@media (min-width: 600px) {
  .banner {
    display: flex;
    flex-direction: column;
    width: 100vw;

    .content {
      display: flex;
      flex-direction: row;
      min-width: 100vw;

      .pad {
        flex: 8 1 auto;
      }
    }
  }
}

//Small devices
@media (max-width: 600px) {
  .banner {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .user {
      img {
        height: 30px !important;
        width: 30px !important;
        margin-top: 10px !important;
        margin-left: 5px !important;
      }
    }

    .content {
      flex-direction: column;
      width: 100vw;
      display: flex;

      .account {
        margin-right: auto;
        margin-left: 10px;
        margin-top: 10px;
        img {
          height: 30px;
          width: 30px;
        }
      }

      .image {
        display: flex;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .content {
      flex-direction: column;
      width: 100vw;
      display: flex;

      .account {
        margin-right: auto;
        margin-left: 10px;
        margin-top: 10px;
        img {
          height: 30px;
          width: 30px;
        }
      }

      .image {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: -45px;
      }
    }

    img {
      height: 150px;
      width: 150px;
      margin-right: 10px;
    }
  }
}

// mobile landscape
@media screen and (orientation: landscape) and (max-device-width: 900px) {
  .banner {
    .user {
      img {
        height: 30px !important;
        width: 30px !important;
        margin-top: 10px !important;
        line-height: 30px !important;
        font-size: 18px;
      }
    }
  }
  .image {
    padding-top: 0px !important;
    padding-right: 5px !important;
    img {
      height: 50px;
      width: 50px;
    }
  }
}
