.loading {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 150px;

    .icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .title {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      color: #fff;
      font-size: 26px;
      margin-bottom: 5px;
    }

    .text {
      display: flex;
      justify-content: center;
      color: #fff;
      margin-top: 5px;
    }

    .spinner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;
    }
  }
}
