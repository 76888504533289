@import '../../variables';

.spinnerOverlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1023;
	background-color: rgba(#393939, 0.9);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.spinner {
		margin: auto;
		margin-top: -45px;
	}
}

.spinner {
	border-radius: 50%;
	-webkit-animation: spin 0.8s ease-in-out infinite; /* Safari */
	animation: spin 1s ease-in-out infinite;
}

.lightSpinner {
	@extend .spinner;
	border: solid $spinner-background;
	border-top: solid $spinner;
}

.darkSpinner {
	@extend .spinner;
	border: solid $spinner-dark-background;
	border-right: solid $spinner-dark;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.primarywhite,
.secondarywhite {
	opacity: 0;
	display: none;
	color: white;
}

.whiteShown {
	text-align: center;
	margin-top: 20px;
	position: absolute;
	width: 100%;
	left: 0;

	.primarywhite,
	.secondarywhite {
		display: block;
		opacity: 1;
	}
}

.secondarywhite {
	margin-top: 5px;
}

.xs {
	width: $spinner-xs;
	height: $spinner-xs;
	border-width: 3px;

	+ .whiteShown {
		.primarywhite {
			font-size: white;
		}
		.secondarywhite {
			font-size: white;
		}
	}
}

.s {
	width: $spinner-s;
	height: $spinner-s;
	border-width: 5px;

	+ .whiteShown {
		.primarywhite {
			font-size: $spinner-s-lead;
		}
		.secondarywhite {
			font-size: $spinner-s-sub;
		}
	}
}

.m {
	width: $spinner-m;
	height: $spinner-m;
	border-width: 8px;

	+ .whiteShown {
		.primarywhite {
			font-size: $spinner-m-lead;
		}
		.secondarywhite {
			font-size: $spinner-m-sub;
		}
	}
}

.l {
	width: $spinner-l;
	height: $spinner-l;
	border-width: 5px;
	+ .whiteShown {
		.primarywhite {
			font-size: $spinner-l-lead;
		}
		.secondarywhite {
			font-size: $spinner-l-sub;
		}
	}
}
