@import "../../variables";
@import "../../mixins";

.modalOverlay {
  @include blocking-overlay-before-show;
  z-index: 1022 !important;
  &.isOpen {
    @include blocking-overlay-show;
  }
}

.modal {
  border: 1px solid white;
  z-index: 20000;
  position: absolute;
  background-color: white;
  width: 400px;
  min-height: 250px;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -1000px);
  transition: all 0.3s linear;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  &.modalEnterDone {
    position: absolute;
    transform: translate(-50%, 0px);
  }

  .modalHeader {
    font-size: 20px;
    color: $modal-title;
    padding: 5px 5px 10px 5px;
  }

  .modalBody {
    display: flex;
    flex: 1;
    flex-direction: column;

    .innerContainer {
      background-color: white;
      display: flex;
      flex: 1 1 auto;
      border-radius: 20px;
      flex-direction: column;
      padding: 5px;
    }
  }

  .modalActions {
    display: flex;
    margin-left: auto;
    padding-top: 10px;
  }
}

@mixin folderBrowserContent {
  .content {
    display: flex;
    flex-direction: column;
    min-width: 800px;
    max-width: 800px;
    min-height: 425px;
    max-height: 425px;
    padding: 10px;

    .error {
      margin-top: 40px;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .content {
      min-width: 490px;
      max-width: 490px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .modal {
    max-width: 90%;
  }
}
