.error {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 50px;

    .inner {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      padding: 40px 80px;
    }

    .icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .title {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      color: #c46262;
      font-size: 26px;
      margin-bottom: 5px;
    }

    .text {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      color: #fff;
      justify-content: center;
      align-items: center;
    }

    .contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      color: #fff;
      font-size: 14px;

      a {
        text-decoration: none;
        color: #fff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
